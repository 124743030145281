import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

const View = ({record}) => {
  const [openPatient, setOpenPatient] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
	const examinationDate = new Date(record.Date*1000).toISOString().replace("T"," ").replace(".000Z", "")
	const followUpExamination = new Date(record.FollowUpExamination*1000).toISOString().replace("T"," ").replace(".000Z", "")
  const { t } = useTranslation();
  
  const PatientInfo = () => (
    <div class="card">
      <div class="card-header" id="headingPatient">
        <h5 class="mb-0">
          <button onClick={() => setOpenPatient(!openPatient)}
            aria-controls="collapse-patient"
            aria-expanded={openPatient}
            class="btn btn-link">
            {t("formPatient")}
          </button>
        </h5>
      </div>
      <Collapse in={openPatient}>
        <div id="collapse-patient">
            <Table striped bordered hover >
              <thead>
                <tr>
                  <th>{t('field')}</th>
                  <th>{t('value')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Fullname')}</td>
                  <td>{record.Patient.FullName}</td>
                </tr>

                <tr>
                  <td>{t('BirthDate')}</td>
                  <td>{record.Patient.BirthDate}</td>
                </tr>

                <tr>
                  <td>{t('Phone')}</td>
                  <td>{record.Patient.Phone1}</td>
                </tr>

                <tr>
                  <td>{t('Email')}</td>
                  <td>{record.Patient.Email}</td>
                </tr>

                <tr>
                  <td>{t('Address')}</td>
                  <td>{record.Patient.Address}</td>
                </tr>

                <tr>
                  <td>{t('City')}</td>
                  <td>{record.Patient.City}</td>
                </tr>

                <tr>
                  <td>{t('PassportId')}</td>
                  <td>{record.Patient.PassportId}</td>
                </tr>

                <tr>
                  <td>{t('Снилс')}</td>
                  <td>{record.Patient.Снилс}</td>
                </tr>
              </tbody>
            </Table>
        </div>
      </Collapse>
    </div>
  )

  return (
    <>
    <li><b>{t('conclusion')}</b>: {record.Conclusion} </li>
   	<li><b>{t('examinationDate')}</b>: {examinationDate} </li>
   	<li><b>{t('followUpExamination')}</b>: {followUpExamination} </li>

    { record.Patient ? null : <b> No patient info.</b> }

    <div id="collapses">
      { record.Patient ? <PatientInfo /> : null }

      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button onClick={() => setOpenForm(!openForm)}
              aria-controls="collapse-form"
              aria-expanded={openForm}
              class="btn btn-link">
              {t("formFields")}
            </button>
          </h5>
        </div>
        <Collapse in={openForm}>
          <div id="collapse-form">
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>{t('field')}</th>
                    <th>{t('value')}</th>
                  </tr>
                </thead>
                <tbody>
                  {record.CustomForms.map((item) =>
                    <tr>
                    	<td>{item.Key}</td>
                    	<td>{item.Value}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
          </div>
        </Collapse>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button onClick={() => setOpenPoints(!openPoints)}
              aria-controls="collapse-points"
              aria-expanded={openPoints}
              class="btn btn-link">
              {t("temperatures")}
            </button>
          </h5>
        </div>
        <Collapse in={openPoints}>
          <div id="collapse-points">
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>{t('point')}</th>
                    <th>{t('skinTemperature')}</th>
                    <th>{t('internalTemperature')}</th>
                  </tr>
                </thead>
                <tbody>
                  {record.Points.map((item) =>
                    <tr>
                      <td>{item.Name}</td>
                      <td>{parseFloat(item.SkinTemperature).toFixed(2)}</td>
                      <td>{parseFloat(item.InternalTemperature).toFixed(2)}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
          </div>
        </Collapse>
      </div>
    </div>
    </>
    );
}



export default View;
import React, { useEffect, useState } from "react";
import Title from "../Title/Title";
import View from "./View"
import {useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth.service";
import { Navigate } from "react-router-dom";
import axios from "axios";
import qs from "qs"
import { API_EXAMINATION } from "../../constants";


const Examination = () => {
  const [record, setRecord] = useState([]);
  const [isRedirect, setIsRedirect] = useState(false)
  const [isAdmin, setIsAdmin] = useState(true)
  const params = useParams()
  let active = 1;

  const getRecord = () => {
    axios.get(API_EXAMINATION, {
      headers: authService.getHeader(),
      params: {
        id: params.id
      }
    }).then((res) => {
      if (res.data && res.data.token_expired) {
        authService.logout()
      }
      if (res.data) {
        const exam = res.data
        setRecord(exam)
      }


    }).catch((error) => {
      // console.log(error)
    })
  };
  useEffect(() => {
    getRecord();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {isRedirect && <Navigate to="/login" />}
      <Title value={t('examination')} />
      {(record.hasOwnProperty('CustomForms')) ? <View record={record}/> : <h1>Record not found</h1>}
    </>
  );

}

export default Examination;

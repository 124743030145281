import React from "react";
import Title from "../Title/Title";
import UpdateUserForm from "./UpdateUserForm";
import { useTranslation } from "react-i18next";

const AddUser = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title value={t("updateUser")} />
      <UpdateUserForm />
    </>
  )
}
export default AddUser;
import axios from 'axios';
import { API_LOGIN, API_REG_CLINIC } from "../constants";

class AuthService {
  /**
   * 
   * @param {string} email - user email/login
   * @param {string} password - user password
   * @returns 
   */
  async login(email, password) {
    try {
      const res = await axios.post(API_LOGIN, {
        email,
        password,
      });
      if (res.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(res.data));
      }
      return res
    } catch (error) {
	    // console.log(API_LOGIN)
      // console.error(error)
      return error.data
    }
  }

  logout() {
    localStorage.removeItem('user')
    window.location.reload();
  }

  /**
   * 
   * @param {string} email - new clinic user email
   * @param {string} password - new clinic user pass
   * @param {string} clinic - new clinic name
   * @param {string} deviceId - device id
   * @param {string} deviceSecret - device secret code
   */
  async regClinic(email, password, clinic, deviceId, deviceSecret) {
    const res = await axios.post(
      API_REG_CLINIC,
      {
        email,
        password,
        deviceId,
        deviceSecret,
      }
    );
    if (res.status === 201) {
      return res.data
    }
    return res.data
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  getHeader() {
    const user = this.getCurrentUser()
    const headers = {
      Authorization: user.accessToken,
    }
    return headers
  }
}

export default new AuthService()

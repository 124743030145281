import React from "react";
import {useNavigate} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {PencilFill} from "react-bootstrap-icons";

const User = (props) =>
{
    const navigate = useNavigate()
    return (
        <tr>
            <td>
                {props.device_id}
            </td>
            <td>
                {props.email}
            </td>
            <td>
                {props.ai_limit}
            </td>
            <td>
                {props.print_limit}
            </td>
            <td>
                <Button onClick={()=> navigate(`/user/${props.id}`)} > <PencilFill/></Button>
            </td>
        </tr>
    );
}

export default User;
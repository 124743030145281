import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            logo: "MWR-Diagnostic",
            examinations: "Examinations",
            examination: "Examination",
            users: "Users",
            addUser: "Add user",
            login: "Login",
            createdAt:"Created at",
            from: "From",
            to: "Till",
            conclusion:"Conclusion",
            found:"Find",
            patient:"Patient",
            clinic:"Clinics title",
            clinicShortName:"Clinics title (short)",
            clinicContacts:"Clinics contacts",
            operators:"Names of operators",
            select:"Select",
            password:"Password",
            deviceId:"Device id",
            devicePasscode:"Device secret code",
            add:"Add",
            loginField: "Login",
            reset: "Reset filter",
            edit: "Edit",
            updateUser: "Update user",
            userUpdated: "User information updated",
            userRegistered: "User registered",
            AILimit: "Available amount of AI use",
            printLimit: "Available amount of prints",
            AILimit_short: "AI left",
            printLimit_short: "Prints left",
            authError: "Authorization error",
            examinationDate: "Date of examination",
            followUpExamination: "Date of follow up examination",
            
            temperatures: "Temperatures",
            formFields: "Form fields",
            field: "Field",
            value: "Value",
            point: "Point",
            skinTemperature: "Skin temperature",
            internalTemperature: "Internal temperature",

            //Patient form
            formPatient: "Patient form",
            fullName: "Fullname",
            BirthDate: "Birth date",
            Phone: "Phone number",
            Email: "Email",
            Address: "Address",
            PassportId: "Passport",
            Снилс: "Snils"
        }
    },
    ru: {
        translation: {
            logo: "РТМ-Диагностика",
            examinations: "Обследования",
            examination: "Обследование",
            users: "Пользователи",
            addUser: "Добавить пользователя",
            login: "Вход",
            createdAt:"Создано",
            from: "С",
            to: "По",
            conclusion:"Заключение",
            found:"Найти",
            patient:"Пациент",
            clinic:"Навзание клиники",
            clinicShortName:"Название клиники (сокращённое)",
            clinicContacts:"Контакты клиники",
            operators:"Операторы",
            select:"Выбрать",
            password:"Пароль",
            deviceId:"Id устройства",
            devicePasscode:"Код устройства",
            add:"Добавить",
            loginField: "Логин",
            reset: "Сбросить фильтр",
            edit: "Редактировать",
            updateUser: "Редактировать пользователя",
            userUpdated: "Данные пользователя обновлены",
            userRegistered: "Пользователь зарегистрирован",
            AILimit: "Доступное количество использования ИИ",
            printLimit: "Доступное количество печатей отчётов",
            AILimit_short: "ИИ осталось",
            printLimit_short: "Отчётов осталось",
            authError: "Неверный логин или пароль",
            examinationDate: "Дата обследования",
            followUpExamination: "Дата следующего обследования",
            
            temperatures: "Температуры",
            formFields: "Поля формы",
            field: "Поле",
            value: "Значение",
            point: "Точка измерения",
            skinTemperature: "Температура кожи",
            internalTemperature: "Глубинная температура",

            //Patient form
            formPatient: "Пациент",
            fullName: "Полное имя",
            BirthDate: "Дата рождения",
            Phone: "Номер телефона",
            Email: "Email",
            Address: "Адрес",
            PassportId: "Паспорт",
            Снилс: "СНИЛС"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
import React from "react";
import {useNavigate} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {PencilFill} from "react-bootstrap-icons";

const Record = ({ num, id, examination_id, created_at, patient, clinic, conclusion }) => {
  const navigate = useNavigate()
  return (
    <tr>
      <td>
        {num}
      </td>
      <td>
        <Button onClick={()=> navigate(`/examination/${id}`)} variant="link">
          {examination_id}
        </Button>
      </td>
      <td>
        {created_at}
      </td>
      <td>
        {clinic}
      </td>
      <td>
        {conclusion}
      </td>
    </tr>
  );
}
export default Record;

import React from "react";
import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import authService from "../../services/auth.service";

export default function Navibar({ lang }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (!user) {
      setIsLoggedIn(false);
      setIsAdmin(false);
    } else {
      setIsLoggedIn(true);
      if (user.role === 'ADMIN')
        setIsAdmin(true);
    }
  }, [])
  const { t, i18n } = useTranslation();
  return (
    <>
      <Navbar variant="primary">
        <Container>
          <Navbar.Brand>
            {t('logo')}
          </Navbar.Brand>

          {isLoggedIn &&
            <Nav className="me-auto">
              <Nav.Link><Link to="/examinations" className={"navLink"}>{t('examinations')}</Link> </Nav.Link>
              {isAdmin && <Nav.Link><Link to="/users" className={"navLink"}>{t('users')}</Link></Nav.Link>}
              {isAdmin && <Nav.Link><Link to="/add_user" className={"navLink"}>{t('addUser')}</Link></Nav.Link>}
            </Nav>
          }
          {!isLoggedIn ?
            <Nav>
              <Nav.Link eventKey={2}>
                <Link to="/login" className={"navLink"}>{t('login')}</Link>
              </Nav.Link>
            </Nav>
            : <Nav>
              <Nav.Link eventKey={2}>
                <Link to="/logout" className={"navLink"} onClick={authService.logout}>Logout</Link>
              </Nav.Link>
            </Nav>
          }
          {<Button className={"me-1"} onClick={() => i18n.changeLanguage('ru')}>RU</Button>}
          {<Button onClick={() => i18n.changeLanguage('en')}>EN</Button>}

        </Container>
      </Navbar>
    </>
  );
}
import React, { useEffect, useState } from "react";
import Title from "../Title/Title";
import { Form, Button, Container, InputGroup, Toast } from "react-bootstrap";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth.service";
import axios from "axios";
import { Navigate } from "react-router-dom";


const Auth = () => {
  const [errorAuth, setErrorAuth] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const [isRedirect, setIsRedirect] = useState(false)
  const { t } = useTranslation();

  const onDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }
  const onPost = async (e) => {
    e.preventDefault();
    const res = await authService.login(data.email, data.password);
    // console.log(res)
    if(res.data && res.status === 200) {
      window.location.reload();
    }
    else {
      setErrorAuth(true)
    }
  }

  useEffect(()=> {
    const user = authService.getCurrentUser()
    if(user) {
      setIsRedirect(true)
    }
  }, [])

  return (
    <>
      {isRedirect && <Navigate to="/examinations" />}
      <Title value={"Authorization"} />
      <Container className={"h-100 justify-content-center"}>
        <Form className={"mt-4 resized"} onSubmit={onPost}>
          <Form.Label className={"col-sm-2"}>{t("loginField")}</Form.Label>
          <Form.Group className="mb-3 d-flex align-items-center">
            <Form.Control type={"email"} required onChange={onDataChange} name="email"/>
          </Form.Group>

          <Form.Label className={"col-sm-2"}>{t("password")}</Form.Label>
          <InputGroup className="mb-3">

            <Form.Control type={showPass ? "text" : "password"} required onChange={onDataChange} name="password"/>

            <InputGroup.Text onClick={() => setShowPass(!showPass)}>
              {
                showPass ? <EyeSlashFill /> : <EyeFill />
              }

            </InputGroup.Text>
          </InputGroup>
          {errorAuth && <Toast><Toast.Body>{t("authError")}</Toast.Body></Toast>}
          <br/>
          <Button variant="primary" type="submit">
            {t("login")}
          </Button>

        </Form>
      </Container>
      
    </>
  );

}

export default Auth;

import React from "react";
import { Table } from "react-bootstrap";
import Record from "./Record/Record";
import { useTranslation } from "react-i18next";


const TableSurvey = ({ records, pagination }) => {
  const { t } = useTranslation();
  return (
    <Table
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th>#</th>
          <th>id</th>
          <th>{t("createdAt")}</th>
          <th>{t("clinic")}</th>
          <th>{t("conclusion")}</th>
        </tr>
      </thead>
      <tbody>
        {records.map((record, index) =>
          <Record
            key={record.id}
            num={(index+1)+pagination.currentPage*pagination.pageSize}
            id={record.id}
            examination_id={record.examinationId}
            created_at={record.created_at}
            clinic={record.clinic}
            conclusion={record.conclusion}
          />
        )}
      </tbody>
    </Table>
  );
}

export default TableSurvey;
import React from "react";
import Title from "../Title/Title";
import AddUserForm from "./AddUserForm/AddUserForm";
import { useTranslation } from "react-i18next";

const AddUser = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title value={t("addUser")} />
      <AddUserForm />
    </>
  )
}
export default AddUser;
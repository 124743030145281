import React, { useEffect, useState } from "react";
import Title from "../Title/Title";
import TableUsers from "./TableUsers/TableUsers";
import { Pagination } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth.service";
import axios from "axios";
import { API_USERS } from "../../constants";

const Users = () => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState({currentPage: 0, pageSize:10})
  const { t } = useTranslation();

  const getUsers = () => {
    // console.log(pagination)
    axios.get(API_USERS, {
      headers: authService.getHeader(),
      params: {
        page: pagination.currentPage,
        perPage: pagination.pageSize,
      }
    }).then((res) => {
      if (res.data && res.data.token_expired) {
        authService.logout()
      }
      
      if(res.data && res.data.users) {
        const users = res.data.users.map((user) => {
          return {
            id: user._id,
            email: user.email,
            password: user.password,
            device_id: user.deviceId,
            ai_limit: user.AILimit,
            print_limit: user.printLimit
          }
        })
        setUsers(users)
      }

      if(res.data && res.data.pagination) {
        const pagi = () => {
          return {
            currentPage: res.data.pagination.currentPage,
            pagesAmount: res.data.pagination.pagesAmount,
            pageSize: res.data.pagination.pageSize
          }
        }
        setPagination(pagi)
      }
    }).catch((error) => {
      console.log(error)
    })
  };

  useEffect(() => {
    const user = authService.getCurrentUser()
    if(!user) {
      setIsRedirect(true)
      return
    } else {
      if(user.role !== 'ADMIN'){
        setIsRedirect(true)
        return;
      }
    }
    getUsers();
  }, []);

  const changePage = (page) => {
    pagination.currentPage = page

    getUsers()
  };

  //Pagination
  let items = [];
  for (let number = 0; number < pagination.pagesAmount; number++) {
    items.push(
      <Pagination.Item 
        key={number} 
        active={number === pagination.currentPage} 
        onClick={() => changePage(number)}>
        {number+1}
      </Pagination.Item>,
    );
  }
  const paginationBasic = (
    <Pagination>{items}</Pagination>
  );

  return (
    <>
    {isRedirect && <Navigate to="/login" />}
      <Title value={t("users")} />
      {users.length && <TableUsers users={users} />}
      {paginationBasic}
    </>
  );
};

export default Users;

import axios from "axios";
import React, {useEffect, useState} from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import authService from "../../services/auth.service";
import { Navigate } from "react-router-dom";
import { API_USER, API_REG_CLINIC } from "../../constants";

const UpdateUserForm = () => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const { t } = useTranslation();
  const [errors, setErrors] = useState([])
  const params = useParams()
  const [data, setData] = useState({
  	id: params.id,
    email: '',
    password: '',
    AILimit: '',
    printLimit: '',
    deviceId: '',

  })


  // console.log(data)
  useEffect(()=> {
    const userFields = axios.get(API_USER, {
      headers: authService.getHeader(),
      params: {
        id: params.id,
      }
    }).then((result)=>{
        setData((prevstate)=>(
          {
            ...prevstate,
            ...result.data.user
          }
        ))
    }).catch((error) => {
      setIsRedirect(true)
    }) 
  }, [])



  const onPost = (e) => {
    e.preventDefault();
    axios.post(API_REG_CLINIC,data, {
      headers: authService.getHeader(),
    }).then((res) => {
      if(res.data === true)
      {
        alert(t("userUpdated"))
      } else if(res.data.errors)
      {
        setErrors(res.data.errors)
      } else {
        setErrors({'error': 'serverError'})
      }

   }).catch((error) => {
      // console.log(error)
    })
  }
  const onDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    const user = authService.getCurrentUser()
    if(!user) {
      setIsRedirect(true)
      return
    } else {
      if(user.role !== 'ADMIN'){
        setIsRedirect(true)
        return;
      }
    }
  }, []);

  return (
    <>
      {isRedirect && <Navigate to="/users" />}
      <Form onSubmit={onPost}>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("deviceId")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="deviceId" value={data.deviceId}/>
        </Form.Group>

        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>E-mail</Form.Label>
          <Form.Control type={"email"} required onChange={onDataChange} name="email" value={data.email}/>
        </Form.Group>

        <InputGroup className="mb-3">
          <Form.Label className={"col-sm-2"}>{t("password")}</Form.Label>
          <Form.Control type={showPass ? "text" : "password"} required onChange={onDataChange} name="password" className={"passCustom"} />
          <InputGroup.Text onClick={() => setShowPass(!showPass)}>
            {
              showPass ? <EyeSlashFill /> : <EyeFill />
            }
          </InputGroup.Text>
        </InputGroup>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("AILimit")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="AILimit" value={data.AILimit}/>
        </Form.Group>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("printLimit")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="printLimit" value={data.printLimit}/>
        </Form.Group>
        <div> 
          {errors>0 && errors.map(error => <h2>{error}</h2>)}
        </div>
        <Button variant={"primary"} onClick={onPost}>{t("add")}</Button>
      </Form>
    </>
  );
}

export default UpdateUserForm;

import React, { useEffect, useState } from "react";
import Title from "../Title/Title";
import TableSurvey from "./TableSurvey/TableSurvey"
import Filter from "./Filter/Filter";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth.service";
import { Navigate } from "react-router-dom";
import axios from "axios";
import qs from "qs"
import { API_EXAMINATIONS, API_CLINICS } from "../../constants";

const Examinations = () => {
  const [records, setRecords] = useState([]);
  const [isRedirect, setIsRedirect] = useState(false)
  const [isAdmin, setIsAdmin] = useState(true)
  const [usersLoading, setUsersLoading] = useState(false)
  const [pagination, setPagination] = useState({currentPage: 0, pageSize:10})
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({startDate: null, endDate: null, clinicId: null})
  const [users, setUsers] = useState([])
  let active = 1;

  const getRecords = (userFilters=filters) => {
    // console.log(filters)
    axios.get(API_EXAMINATIONS, {
      headers: authService.getHeader(),
      params: {
        page: pagination.currentPage,
        perPage: pagination.pageSize,
        filterEnd: userFilters.endDate,
        filterStart: userFilters.startDate,
        filterDeviceId: userFilters.clinicId
      }
    }).then((res) => {
      
      console.log(userFilters.clinicId)

      if (res.data && res.data.token_expired) {
        authService.logout()
      }


      if (res.data && res.data.examinations) {
        const exams = res.data.examinations.map((exam) => {
          return {
            id: exam._id,
            examinationId: exam.ExaminationId,
            created_at: new Date(exam.Date*1000).toISOString().replace("T"," ").replace(".000Z", ""),
            clinic: users.find(x => {return x.id === exam.ClinicsId}).clinic_name,
            patient: exam.PatientId,
            conclusion: exam.Conclusion
          }
        })

        setRecords(exams)
        setTotal(res.data.total)
      }

      if(res.data && res.data.pagination) {
        const pagi = () => {
          return {
            currentPage: res.data.pagination.currentPage,
            pagesAmount: res.data.pagination.pagesAmount,
            pageSize: res.data.pagination.pageSize
          }
        }
        setPagination(pagi)
      }

    }).catch((error) => {
      // console.log(error)
    })
  };

  const getUsers = () => {
    axios.get(API_CLINICS, {
      headers: authService.getHeader(),
      params: {
        page: 1,
        perPage: 200,
      }
    }).then((res) => {
      if (res.data && res.data.token_expired) {
        authService.logout()
      }

      if(res.data && res.data.clinics) {
        const clinics = res.data.clinics.map((clinic) => {
          if(clinic.deviceId) {
            var name = clinic.deviceId
          } else {
            var name = "Unnamed"
          }

          return {
            id: clinic._id.$oid,
            clinic_name: name,
          }
        })
        // console.log(clinics)
        setUsers(clinics)
        setUsersLoading(true)
      }
    }).catch((error) => {
      // console.log(error)
    })
  };


  useEffect(() => {
    const user = authService.getCurrentUser()
    if (!user) {
      setIsRedirect(true)
      return
    } else {
      if (user.role !== 'ADMIN') {
        setIsAdmin(false);
      }
    }
    getUsers();
  }, []);

  useEffect(() => {
    const user = authService.getCurrentUser()
    if (!user) {
      setIsRedirect(true)
      return
    } else {
      if (user.role !== 'ADMIN') {
        setIsAdmin(false);
      }
    }
    getRecords();
  }, [usersLoading]);

  const getRecordsWithFilter = async (e, startDate, endDate, clinicId) => {
    e.preventDefault();

    const f = {
      startDate,
      endDate,
      clinicId
    }

    setFilters(f)
    
    getRecords(f)
  }

  const changePage = (page) => {
    pagination.currentPage = page

    getRecords()
  };


  //Pagination
  let rows = [];
  for (let number = 0; number < pagination.pagesAmount; number++) {
    if(Math.abs(pagination.currentPage - number) < 3 
      || number <= 3 
      || pagination.pagesAmount - number < 4
      // || pagination.pagesAmount < 10
      ) {
        rows.push(
          <Pagination.Item 
            key={number} 
            active={number === pagination.currentPage} 
            onClick={() => changePage(number)}>
            {number+1}
          </Pagination.Item>,
        );
    } else if (Math.abs(pagination.currentPage - number) == 4)
    {
      rows.push(
        <Pagination.Item 
          key={number}>
          {"..."}
        </Pagination.Item>,
      );
    }
    
  }
  const paginationBasic = (
    <Pagination>{rows}</Pagination>
  );

  const { t } = useTranslation();
  return (
    <>
      {isRedirect && <Navigate to="/login" />}
      <Title value={t('examinations')} />
      {<Filter records={users} isAdmin={isAdmin} search={getRecordsWithFilter}/>}
      {(records.length > 0) ? <TableSurvey records={records} pagination={pagination}/> : <h1>Records not found</h1>}
      {paginationBasic}
    </>
  );

}

export default Examinations;

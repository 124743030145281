import Navibar from './components/Navibar/Navibar'
import Auth from "./components/Auth/Auth";
import Examinations from "./components/Examinations/Examinations"
import Examination from "./components/Examination/Examination"
import Users from "./components/Users/Users";
import AddUser from "./components/AddUser/AddUser";
import {Container} from "react-bootstrap";
import "./style.scss";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import UpdateUser from "./components/UpdateUser/UpdateUser";

const App = (props) => {
    return (
        <Router>
            <div className="App">
                <Navibar/>

                <Container>
                    <Routes>
                        <Route exact path={"/examinations"} element={<Examinations/>}/>
                        <Route exact path={"/users"} element={<Users/>}></Route>
                        <Route exact path={"/add_user"} element={<AddUser/>}></Route>
                        <Route exact path={"/user/:id"} element={<UpdateUser/>}></Route>
                        <Route exact path={"/examination/:id"} element={<Examination/>}></Route>
                        <Route exact path={"/login"} element={<Auth/>}></Route>
                        <Route exact path={"/logout"} element={<Navigate to='/login'/>}></Route>
                    </Routes>
                </Container>
            </div>
        </Router>
    );
}

export default App;

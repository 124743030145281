import React from "react";
import {Table} from "react-bootstrap";

import User from "./User/User";
import {useTranslation} from "react-i18next";

const TableUsers = ({users}) => {
    const { t } = useTranslation();
    return (
        <Table
            striped
            bordered
            hover
        >
            <thead>
            <tr>
                <th>#</th>
                <th>E-mail</th>
                <th>{t("AILimit_short")}</th>
                <th>{t("printLimit_short")}</th>
                <th>{t("edit")}</th>
            </tr>
            </thead>
            <tbody>
            {users.map((user) =>
                    <User
                        key={user.id}
                        id={user.id}
                        email={user.email}
                        ai_limit={user.ai_limit}
                        device_id={user.device_id}
                        print_limit={user.print_limit}
                    />
            )}
            </tbody>
        </Table>
    );
}

export default TableUsers;
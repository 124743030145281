import {React, useRef, useState} from "react";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import '../../../style.scss';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import './DatePicker/DatePicker.css';
import './DatePicker/Calendar.css';
import { useTranslation } from "react-i18next";

const Filter = ({ records, isAdmin, search }) => {
  const [valueFrom, onChangeFrom] = useState();
  const [valueTo, onChangeTo] = useState(new Date(new Date().setHours(24, 59, 59)));
  const [clinics, setClinics] = useState([]);
  const [concl, setConcl] = useState('');
  const { t, i18n } = useTranslation();
  const refMultiselect = useRef()

  // console.log(clinics)
  return (
    <>
      <Card className={"mb-3 p-3"}>
        <Form onSubmit={(e) => search(e, valueFrom, valueTo, clinics, concl)}>
          <Row>
            <Col>
              <Form.Label >{t("createdAt")}</Form.Label>
              <Form.Group className="mb-3 d-flex">

                <Form.Label className={"me-2 mt-2"}>{t("from")}</Form.Label>
                <DatePicker
                  format="yyyy-MM-dd"
                  locale={i18n.language}
                  onChange={onChangeFrom}
                  value={valueFrom}
                />
                <Form.Label className={"m-2"}>{t("to")}</Form.Label>
                <DatePicker
                  format="yyyy-MM-dd"
                  locale={i18n.language}
                  onChange={onChangeTo}
                  value={valueTo}
                />
              </Form.Group>
            </Col>
            {isAdmin && <Col >
              <Form.Label>{t("deviceId")}</Form.Label>
              <Form.Group className="mb-3 d-flex align-items-center">
                <Multiselect ref={(ref)=>refMultiselect.current = ref}
                  options={records.map((clinic) => {
                    return {
                      id: clinic.id,
                      name: clinic.clinic_name,
                    }
                  })}
                  closeIcon={"cancel"}
                  style={{
                    chips: { // To change css chips(Selected options)
                      background: '#4dd4ac',
                      marginBottom: 0
                    },
                    multiSelectContainer: {
                      marginTop: 0,
                    }
                  }}
                  className={"clinic"}
                  // Options to display in the dropdown
                  // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={(selectedList, selectedItem) => {
                    const tmpClinics = selectedList.map((e) => e.id)
                    setClinics(tmpClinics)
                  }} // Function will trigger on select event
                  onRemove={(selectedList, selectedItem) => {
                    const tmpClinics = selectedList.map((e) => e.id)
                    setClinics(tmpClinics)
                  }} 
                  displayValue="name" // Property name to display in the dropdown options
                  placeholder={t("select")}
                />
              </Form.Group>
            </Col>}
          </Row>

          <Form.Group className="d-flex ">
            <Button variant={"primary"} className={"me-1"} type="submit">{t("found")}</Button>
            <Button variant={"primary"}
                    onClick={()=> {
                      onChangeFrom('');
                      onChangeTo('');
                      setClinics([])
                      refMultiselect.current.resetSelectedValues()
                    }}>
              {t("reset")}</Button>
          </Form.Group>

        </Form>
      </Card>
    </>
  )
    ;
}

export default Filter;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";
import authService from "../../../services/auth.service";
import { Navigate } from "react-router-dom";
import { API_REG_CLINIC } from "../../../constants";

const AddUserForm = () => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const { t } = useTranslation();
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({
    email: '',
    password: '',
    AILimit: '',
    printLimit: '',
    deviceId: ''

  })

  const onPost = (e) => {
    e.preventDefault();
    axios.post(API_REG_CLINIC,data, {
	    headers: authService.getHeader(),
    }).then((res) => {
      if (res.data && res.data.token_expired) {
        authService.logout()
      }
      if(res.data === true)
      {
        alert(t("userRegistered"))
      } else if(res.data.errors)
      {
	     // console.log(res.data.errors)
	   // setErrors(res.data.errors)
      //} else {
      //  setErrors({'error': 'serverError'})
      }
//	console.log(res.data)
   }).catch((error) => {
      // console.log(error)
    })
  }
  const onDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    const user = authService.getCurrentUser()
    if(!user) {
      setIsRedirect(true)
      return
    } else {
      if(user.role !== 'ADMIN'){
        setIsRedirect(true)
        return;
      }
    }
  }, []);


  return (
    <>
      {isRedirect && <Navigate to="/login" />}
      <Form onSubmit={onPost}>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("deviceId")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="deviceId"/>
        </Form.Group>

        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>E-mail</Form.Label>
          <Form.Control type={"email"} required onChange={onDataChange} name="email" />
        </Form.Group>

        <InputGroup className="mb-3">
          <Form.Label className={"col-sm-2"}>{t("password")}</Form.Label>
          <Form.Control type={showPass ? "text" : "password"} required onChange={onDataChange} name="password" className={"passCustom"} />
          <InputGroup.Text onClick={() => setShowPass(!showPass)}>
            {
              showPass ? <EyeSlashFill /> : <EyeFill />
            }
          </InputGroup.Text>
        </InputGroup>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("AILimit")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="AILimit" value={data.AILimit}/>
        </Form.Group>
        <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Label className={"col-sm-2"}>{t("printLimit")}</Form.Label>
          <Form.Control type={"text"} required onChange={onDataChange} name="printLimit" value={data.printLimit}/>
        </Form.Group>
        <div> 
          {errors.map(error => <h2>{error}</h2>)}
        </div>
        <Button variant={"primary"} onClick={onPost}>{t("add")}</Button>
      </Form>
    </>
  );
}

export default AddUserForm;
